import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    // Main web layout
    {
        path: '/',
        name: 'Main',
        component: () => import('@/views/main/MainPageView.vue'),
        meta: { layout: 'MainLayout' }
        // children: [{ path: "", component: () => import("pages/Index.vue") }],
    },
    // Deal
    {
        path: '/deal/:id?',
        name: 'Deal',
        component: () => import('@/views/main/deals/DealView.vue'),
        meta: { layout: 'MainLayout' }
    },
    // Deal redirector
    {
        path: '/l/:short?',
        name: 'DealRedirector',
        component: () => import('@/views/main/deals/DealRedirector.vue'),
        layout: 'EmptyLayout',
        meta: {
            layout: 'EmptyLayout',
            supressCookiesDialog: true
        }
    },
    // Deal publisher 
    {
        path: '/deal/dealeditor/:id?',
        name: 'DealEditor',
        component: () => import('@/views/main/deals/DealPublish.vue'),
        meta: { layout: 'MainLayout' }
    },
    // User profile
    {
        path: '/profile/',
        name: 'UserProfile',
        meta: { layout: 'MainLayout' },
        children: [
            {
                path: 'deals', name: 'UserProfileDeals',
                component: () => import('@/views/main/profile/MyDeals.vue'),
                meta: { layout: 'MainLayout' }
            }
        ]
    },
    // Admin portal
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('@/views/main/admin/AdminView.vue'),
        meta: { layout: 'MainLayout' },
        children: [
            {
                path: 'users', name: 'AdminUsers',
                component: () => import(
                    '@/views/main/admin/sub/UsersView.vue'
                ),
                meta: { layout: 'MainLayout' },
                children: [
                    {
                        path: 'all', name: 'AdminUsersAll',
                        component: () => import('@/components/admin/users/List.vue'),
                        meta: { layout: 'MainLayout' },
                    },
                    {
                        path: ':id', name: 'AdminUsersEditCreate',
                        component: () => import('@/components/admin/users/EditCreate.vue'),
                        meta: { layout: 'MainLayout' },
                        children: [
                            {
                                path: 'data', name: 'AdminUserBasicData',
                                component: () => import('@/components/admin/users/editcreate/BasicData.vue'),
                                meta: { layout: 'MainLayout' },
                            },
                            {
                                path: 'vdata', name: 'AdminUserVerifiableData',
                                component: () => import('@/components/admin/users/editcreate/VerifiableData.vue'),
                                meta: { layout: 'MainLayout' },
                            },
                            {
                                path: 'perm', name: 'AdminUserPermissions',
                                component: () => import('@/components/admin/users/editcreate/Permissions.vue'),
                                meta: { layout: 'MainLayout' },
                            },
                        ]
                    },
                ]
            },
            {
                path: 'enterprises', name: 'AdminEnterprises',
                component: () => import('@/views/main/admin/sub/EnterprisesView.vue'),
                meta: { layout: 'MainLayout' },
                children: [
                    {
                        path: 'all', name: 'AdminEnterprisesAll',
                        component: () => import('@/components/admin/enterprises/List.vue'),
                        meta: { layout: 'MainLayout' },
                    },
                    {
                        path: ':id', name: 'AdminEnterprisesEditCreate',
                        component: () => import('@/components/admin/enterprises/EditCreate.vue'),
                        meta: { layout: 'MainLayout' },
                        children: [
                            {
                                path: 'data', name: 'AdminEnterpriseBasicData',
                                component: () => import('@/components/admin/enterprises/editcreate/Data.vue'),
                                meta: { layout: 'MainLayout' },
                            },
                            {
                                path: 'perm', name: 'AdminEnterprisePermissions',
                                component: () => import('@/components/admin/enterprises/editcreate/Permissions.vue'),
                                meta: { layout: 'MainLayout' },
                            },
                            {
                                path: 'referrals', name: 'AdminEnterpriseReferrals',
                                component: () => import('@/components/admin/enterprises/editcreate/Referrals.vue'),
                                meta: { layout: 'MainLayout' },
                            }
                        ]
                    }
                ]
            }
        ]
    },

    // Ads
    {
        path: '/prom/',
        name: 'Promo',
        children: [
            {
                path: 'squarepromo', name: 'SquarePromo',
                component: () => import('@/views/promos/SquarePromo.vue'),
                meta: { layout: 'EmptyLayout', supressCookiesDialog: true }
            }
        ]
    },

    // Login modal
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/main/login/LoginModal.vue'),
        meta: {
            layout: 'LoginLayout',
            bindLogin: true,
        }
    },

    // TODO: Remove
    // Test page
    {
        path: '/test',
        name: 'Test',
        component: () => import('@/views/main/TestComponent.vue'),
    },

    // This is for handle 404
    {
        path: '/:catchAll(.*)*',
        component: () => import('@/views/errors/HTTP404.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        if (to.path === from.path && to.fullPath !== from.fullPath) {
            // If only the params change, we don't want to scroll to top
            return false;
        }
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        } else {
            // Scroll to top
            window.scrollTo(0, 0);

            // Scroll to top (Inner layout)
            document.documentElement.scrollTop = 0; // HTML Root element
            document.body.scrollTop = 0; // Body root element
        }
    }
});

export default router;
