<template>
    <div class="text-white px-4 py-8 md:px-6 lg:px-8 footergrid flex align-items-center flex-column">
        <div class="grid pb-6 border-bottom-1 border-300 pagecontainedwidth">
            <div class="col-12 mb-3">
                <Image :src="'/brand/logo/MainLogoNegative.png'"
                       :srcset="'/brand/logo/MainLogoNegative@0.5x.png 0.5x ' +
                           '/brand/logo/MainLogoNegative@2x.png 2x '"
                       height="34" alt="Logo de Holachollo" 
                       class="mr-0 lg:mr-6"/>
            </div>
            <div class="col-12 md:col-3 text-white pr-6">
                <div class="font-bold line-height-3 mb-3 text-lg">Nuestra misión</div>
                <p class="text-sm">Las compras por internet son el motor de muchos particulares y empresas. 
                    Holachollo es el sitio donde las compras por internet se hacen al mejor precio
                    y con las mejor comunidad. 
                </p>
                <a class="block cursor-pointer line-height-3">
                    <i class="pi pi-envelope pr-2 text-blue-600" style="font-size: 1rem; line-height: 1.3em;"></i>
                    Contacta con nosotros
                </a>
            </div>
            <div class="col-12 md:col-3">
                <div class="font-bold line-height-3 mb-3 text-lg">Sobre nosotros</div>
                <a class="block cursor-pointer line-height-3 mb-2">Conócenos</a>
                <a class="block cursor-pointer line-height-3 mb-2"
                   @click="openCookiesDialog">
                    Configuración de cookies
                </a>
                <a class="block cursor-pointer line-height-3 mb-2">Hazte partner</a>
                <a class="block cursor-pointer line-height-3 mb-2">Contáctanos</a>
            </div>
            <div class="col-12 md:col-3 text-white">
                <div class="font-bold line-height-3 mb-3 text-lg">
                    Legal
                </div>
                <a class="block cursor-pointer line-height-3 mb-2"
                    target="_blank"
                    href="https://info.holachollo.com/reglas-de-publicacion-de-ofertas-y-comentarios/">
                    Reglas para publicar
                </a>
                <a class="block cursor-pointer line-height-3 mb-2" 
                    target="_blank"
                    href="https://info.holachollo.com/politica-de-privacidad/">
                    Política de privacidad
                </a>
                <a class="block cursor-pointer line-height-3 mb-2"
                    target="_blank"
                    href="https://info.holachollo.com/politica-sobre-cookies/">
                    Política sobre cookies
                </a>
                <a class="block cursor-pointer line-height-3"
                    target="_blank"
                    href="https://info.holachollo.com/terminos-de-uso/">
                    Aviso legal y condiciones
                </a>
            </div>
            <div class="col-12 text-right md:col-3">
                <div class="font-bold line-height-3 mb-3 text-lg">Síguenos en redes</div>
                <Button icon="pi pi-telegram" class="socialicon ml-2" rounded
                        aria-label="Filter" />
                <Button icon="pi pi-twitter" class="socialicon ml-2" rounded
                        aria-label="Filter" />
                <Button icon="pi pi-facebook" class="socialicon ml-2 mr-0" rounded
                        aria-label="Filter" />
                
            </div>
        </div>
        <div class="grid align-items-center mt-6 pagecontainedwidth">
            <div class="col-12 text-gray-400 md:col-6 pr-6 text-sm">
                © 2017 - {{ year }} Todos los derechos reservados. <br/>Holachollo es una marca con licencia y registro de Suricata Solutions.
            </div>
            <div class="text-right col-12 md:col-6 text-white">
                Hecho con ❤️ por <a href="https://www.suricatta.es" target="_blank">Suricatta</a>
            </div>
        </div>
    </div>
</template>

<script>

// Components
import Image from 'primevue/image';
import Button from 'primevue/button';

// Bus component
import useEmitter from '@/helpers/composables/useEmitter';

export default {
    components: {
        Image, Button
    },
    emits: [],
    setup() {
        
        const year = new Date().getFullYear();
        const emitter = useEmitter();
        
        const openCookiesDialog = () => {
            emitter.emit('show-cookies-dialog');
        };

        return { 
            year, openCookiesDialog
        };
    }
};
</script>

<style>
.socialicon .p-button-icon {
    font-size: 1.4em !important;
    border: 0px !important;
    color: #CCC !important;
    transition-property: color;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.socialicon {
    border: 0px !important;
    background-color: #666 !important;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.socialicon:enabled:active {
    background-color: #bbb !important;
}

.socialicon:hover .p-button-icon {
    color: #FFF !important;
}

.socialicon:hover {
    background-color: #999 !important;
}
</style>

<style scoped>
.footergrid {
    background: #000411;
}

.footergrid a {
    color: white;
    transition-property: color;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.footergrid a:hover {
    color: #ccc;
}
</style>