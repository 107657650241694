<script setup>
import { ref, watch, computed, nextTick } from 'vue';
import { useConsentStore } from '@/store/data/legal/consentStore.js';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ToggleButton from 'primevue/togglebutton';
import InputSwitch from 'primevue/inputswitch';
import Checkbox from 'primevue/checkbox';
import ScrollPanel from 'primevue/scrollpanel';
import AnimatedResize from '@/components/_common/layout/animatedResize.vue';

import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();

// Bus component
import useEmitter from '@/helpers/composables/useEmitter';
const emitter = useEmitter();

const consentStore = useConsentStore();
const showCookieDialog = ref(false);
const showConfigDialog = ref(false);

// Watch with old new parameters
watch(() => consentStore.consentReady, async (newv, old) => {
    await nextTick(); await nextTick(); await nextTick(); await nextTick();
    if (newv && old !== newv) {
        // If route meta has a supressCookiesDialog set to true, do not show the dialog
        let isConsentSaved = await consentStore.hasSavedConsent;
        if(route.meta.supressCookiesDialog !== true) {
            showCookieDialog.value = !isConsentSaved;
        }
    }
});

watch(
    () => route.meta,
    (newMeta) => {
        if (newMeta.supressCookiesDialog !== undefined && newMeta.supressCookiesDialog === true) {
            showCookieDialog.value = false; 
        }
    },
    { immediate: true } // Para ejecutar también cuando se monte por primera vez
);

const testIfShowDialog = function() {

}

const acceptCookies = () => {
    consentStore.acceptAllCookies();
    showCookieDialog.value = false;
    showConfigDialog.value = false;
};

const rejectCookies = () => {
    consentStore.rejectAllCookies();
    showCookieDialog.value = false;
    showConfigDialog.value = false;
};

const configureCookies = function() {
    showConfigDialog.value = true;
    showCookieDialog.value = false;
};

const configureGoBack = async function() {
    if(await consentStore.hasSavedConsent) {
        showConfigDialog.value = false;
        showCookieDialog.value = false;
    } else {
        showConfigDialog.value = false;
        showCookieDialog.value = true;
    }
};

const configureSaveCookies = function() {
    consentStore.saveConsent();
    showConfigDialog.value = false;
};

// Callback from emitter to show form any component
emitter.on('show-cookies-dialog', () => {
    configureCookies();
});

// Cookies actions
const updateCookieElement = function(cookieName, updateValue) {
    consentStore.consentData[cookieName].accepted = updateValue;
};

const updateCategoryElement = function(categoryName, updateValue) {
    consentStore.allCategoriesWithItems.find(category => category.name === categoryName)
        .items.forEach(function(item) {
            consentStore.consentData[item.name].accepted = updateValue;
        });
};
</script>

<template>
    <!-- Initial cookie dialog -->
    <Dialog 
        v-model:visible="showCookieDialog"
        class="cookiesDialog" :closable="false"
        :modal="true" :draggable="false" :resizable="false" 
        position="bottom" :baseZIndex="1000" :maskStyle="{ background: 'rgba(0, 0, 0, 0.5)' }"
        style="width: 100vw; margin: 0; border-radius: 0;">

        <template #header>
            <div></div>
        </template>

        <div class="flex flex-column m-auto maincontainedwidth">
            <div class="flex flex-column md:flex-row justify-content-between md:align-items-center">
                <h2 class="m-0 p-0 mt-2 mb-2 text-color">
                    Hola, somos las cookies
                </h2>
                <div class="text-black-alpha-200 ml-2 p-0 underline hidden md:block
                    border-round cursor-pointer"
                    @click="rejectCookies">
                    Continuar sin aceptar
                </div>
            </div>
            <div>
                Utilizamos cookies opcionales para mejorar tu experiencia en 
                nuestros sitios web, con conexiones a páginas de compra y redes
                sociales, y para recoger datos personalizados en función de 
                tu actividad en línea. Puedes no complicarte y aceptar todo.
                Puedes gestionar cada cookie individualmente en el botón 
                'Gestionar cookies' o rechazarlas todas, en cuyo caso puede
                que algunas partes de la página no funcionen o no sean accesibles. 
                <span class="inline-block md:hidden cursor-pointer text-black-alpha-200 underline"
                    @click="rejectCookies">
                    Continuar sin aceptar
                </span>
            </div>
            <div class="flex justify-content-end mt-3">
                <div class="w-full md:w-auto">
                    <Button label="Configurar cookies" outlined 
                        class="p-button-secondary mb-2 md:mb-0 md:mr-2 w-full md:w-auto" 
                        @click="configureCookies" />
                    <Button label="Aceptar todo" severity="secondary"
                        class="w-full md:w-auto"
                        @click="acceptCookies" />
                </div>
            </div>
        </div>
    </Dialog>

    <!-- Configuration cookie dialog -->
    <Dialog 
        v-model:visible="showConfigDialog"
        class="cookiesConfigDialog" :closable="false"
        :modal="true" :draggable="false" :resizable="false" 
        position="center" :baseZIndex="1001" :maskStyle="{ background: 'rgba(0, 0, 0, 0.5)' }"
        style="width: 38em; margin: 0; border-radius: 0;">

        <template #header>
            <div></div>
        </template>
        
        <div class="flex flex-column m-auto">
            <div class="flex justify-content-between align-items-center">
                <h2 class="m-0 p-0 mt-2 mb-2 text-color">
                    Configuración de cookies
                </h2>
                <div class="text-black-alpha-200 ml-2 p-0 underline hidden md:block
                    border-round cursor-pointer"
                    @click="rejectCookies">
                    Rechazar todas
                </div>
            </div>

            <div class="mt-2">
                <AnimatedResize :overflowhidden="false" :animationDuration="0.15"
                    style="width: 100%; max-height: 60vh; overflow-y: auto;">
                    <div>
                        En HolaChollo usamos cookies para mejorar la navegación y funcionalidades. 
                        Mantenerlas activadas optimiza la página y nuestros servicios. Revise su uso 
                        y ajuste la configuración si lo desea.
                        <span class="inline-block md:hidden cursor-pointer text-black-alpha-200 underline"
                            @click="rejectCookies">
                            Rechazar todas
                        </span>
                    </div>
                    <Accordion class="mt-2">
                        <AccordionTab contentClass="cookiesAccordionTabContent"
                            v-for="category in consentStore.allCategoriesWithItems"
                            :key="category.name">
                            <template #header>
                                <div class="flex justify-content-between w-full cookiesAccordionTabHeader">
                                    <div class="">
                                        <div>
                                            <div class=" text-bluegray-800">
                                                {{ category.name }}
                                            </div>
                                            <div class="text-sm text-bluegray-400">
                                                {{ category.description }}
                                            </div>
                                        </div>
                                    </div>
                                    <div @click.stop="false" class="pl-3 pr-2 z-2 flex flex-row align-items-center">
                                        <InputSwitch :modelValue="category.items.every(item => item.accepted)" 
                                            @update:modelValue="ev => updateCategoryElement(category.name, ev)"
                                            class="cookieSwitch" :disabled="category.mandatory"
                                            :class="{ 'opacity-10': category.mandatory, 'notallowed': category.mandatory }" />
                                    </div>
                                </div>
                            </template>
                            <div v-for="elem in category.items" :key="elem" class="flex justify-content-between align-items-center">
                                <div class="my-1">
                                    <div class="font-semibold text-bluegray-600">
                                        {{ elem.name }}
                                    </div>
                                    <div class=" text-sm text-bluegray-400">
                                        {{ elem.description }}
                                    </div>
                                </div>
                                <div class="flex flex-row align-items-center pl-5">
                                    <Checkbox :modelValue="elem.accepted" binary
                                        @update:modelValue="ev => updateCookieElement(elem.name, ev)"
                                        :disabled="category.mandatory"
                                        :class="{ 'opacity-40 notallowed': category.mandatory, 'notallowed': category.mandatory }" />
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </AnimatedResize>
            </div>

            <div class="mt-3">
                <div class="flex justify-content-between w-full">
                    <Button label="Volver" outlined class="p-button-secondary mr-1 w-full" 
                        @click="configureGoBack" />
                    <Button label="Confirmar" outlined class="ml-1" severity="secondary w-full"
                        @click="configureSaveCookies" />
                </div>
                <div class="w-full mt-2">
                    <Button label="Permitir todas" class="" severity="secondary w-full"
                        @click="acceptCookies" />
                </div>
            </div>
        </div>

    </Dialog>
</template>

<style scoped>

</style>

<style>
.notallowed {
    cursor: not-allowed !important;
}
.cookiesDialog .p-dialog-content {
    border-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.cookiesConfigDialog .p-dialog-header {
    padding: 0px !important;
}

.cookiesConfigDialog .p-dialog-content {
    padding: 1em 2em !important;
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
}

.cookiesConfigDialog .p-accordion-header-link {
    padding: 0.4em 0.7em !important;
    box-shadow: none !important;
}

.cookiesAccordionTabContent .p-accordion-content {
    padding: 0.6em 1.3em !important;
}

.cookieSwitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--text-color-secondary) !important;
}
</style>
