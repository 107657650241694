<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MainLayout from './views/layouts/main/MainLayout.vue';
import LoginLayout from './views/layouts/login/LoginLayout.vue';
import EmptyLayout from './views/layouts/empty/EmptyLayout.vue';
import Toast from 'primevue/toast';
import cookiesDialog from './components/cookies/cookiesDialog.vue';

const route = useRoute();
const router = useRouter();

const layout = ref('EmptyLayout');
const defaultLayout = 'MainLayout';
const showCookiesDialog = ref(true);

const layouts = {
    MainLayout,
    LoginLayout,
    EmptyLayout,
};

const currentLayout = computed(() => layouts[layout.value] || EmptyLayout);

watch(
    () => route.meta.layout,
    (layoutMeta) => {
        if (layoutMeta !== undefined && layout.value !== layoutMeta) {
            layout.value = layoutMeta;
        }
    },
    { immediate: true }
);

// Watch if route is DealRedirector
watch(
    () => route.name,
    (routeName) => {
        if (routeName === 'DealRedirector') {
            layout.value = layouts['EmptyLayout'];
        }
    }
);

watch(
    () => route.meta.supressCookiesDialog,
    (hideCookiesMeta) => {
        showCookiesDialog.value = hideCookiesMeta === false;
    },
    { immediate: true }
);
</script>

<template>
    <Toast />
    <div>
        <div>
            <component :is="currentLayout" />
        </div>
        <div>
            <cookiesDialog />
        </div>
    </div>
</template>

<style>
html {
    overflow-y: auto !important;
}
</style>